import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { AttractionsComponent } from './components/attractions/attractions.component';
import { DeliveryareaComponent } from './components/deliveryarea/deliveryarea.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { InstructionComponent } from './components/instruction/instruction.component';
import { OffersComponent } from './components/offers/offers.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    IntroductionComponent,
    AttractionsComponent,
    DeliveryareaComponent,
    ImpressumComponent,
    InstructionComponent,
    OffersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
